<template>
  <!-- 新增自定义底板布局 -->
  <BaseDesignCustomFloor v-bind="permisionsList" />
</template>

<script>
import BaseDesignCustomFloor from './module/baseDesignCustomFloor'
import { checkPermission } from '@/utils'
export default {
  components: {
    BaseDesignCustomFloor
  },

  data() {
    return {
      permisionsList: {
        hasDoAdd: checkPermission(['externaladmin:user:addCustomFloorLayout:create'])
      }
    }
  }
}
</script>

<style>
</style>
